import { FunctionComponent } from 'react'
import { Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import IconForRequiredInput from './IconForRequiredInput'
import TooltipForInfo from './TooltipForInfo'

type Props = {
  label: string
  text: string
  selected: string[]
  availableTypes: string[]
  placeholder: string
  required: boolean
  onChange: (selectedValue: string[]) => void
}

const FormTypeSelect: FunctionComponent<Props> = props => {
  return (
    <Form.Group>
      <Form.Label>
        {props.label} <IconForRequiredInput show={props.required} />
      </Form.Label>
      <TooltipForInfo text={props.text} />
      <Typeahead
        id="form-type-select"
        labelKey="name"
        multiple
        selected={props.selected}
        options={props.availableTypes}
        placeholder={props.placeholder}
        renderMenuItemChildren={option => <span>{option}</span>}
        onChange={props.onChange}
        emptyLabel='Nessuna tipologia trovata'
      />
    </Form.Group>
  )
}

export default FormTypeSelect
