import Axios from 'axios'
import iSearchCitiesResponseDto from '../../common/iSearchCitiesResponseDto'
import { API_URL_SEARCH_CITIES } from '../config/routes'

export default class AreaFinder {
  /**
   * Find a list of cities matching with the provided query.
   */
  searchCities(query: string): Promise<string[]> {
    return Axios.post<iSearchCitiesResponseDto>(API_URL_SEARCH_CITIES, { query })
      .then(response => response.data.foundCities)
      .catch(() => [])
  }
}
