import { FunctionComponent } from 'react'
import { Form } from 'react-bootstrap'
import IconForRequiredInput from './IconForRequiredInput'
import TooltipForInfo from './TooltipForInfo'

type Props = {
  label: string
  value: string
  options: string[]
  text: string
  required: boolean
  onChange: (selectedOption: string) => void
}

const FormSelect: FunctionComponent<Props> = props => (
  <Form.Group>
    <Form.Label>
      {props.label} <IconForRequiredInput show={props.required} />
    </Form.Label>
    <TooltipForInfo text={props.text} />
    <Form.Control as="select" value={props.value} onChange={event => props.onChange(event.currentTarget.value)}>
      <option></option>
      {props.options.map(option => (
        <option value={option} key={option}>{option}</option>
      ))}
    </Form.Control>
  </Form.Group>
)

export default FormSelect
