import { FunctionComponent } from 'react'
import { Form } from 'react-bootstrap'
import IconForRequiredInput from './IconForRequiredInput'

type Props = {
  label: string
  placeholder: string
  value: string
  required: boolean
  type?: 'text' | 'email' | 'tel'
  onChange: (value: string) => void
}

const FormInput: FunctionComponent<Props> = props => (
  <Form.Group>
    <Form.Label>
      {props.label} <IconForRequiredInput show={props.required} />
    </Form.Label>
    <Form.Control
      type={props.type || 'text'}
      placeholder={props.placeholder}
      value={props.value}
      onChange={event => props.onChange(event.currentTarget.value)}
    />
  </Form.Group>
)

export default FormInput
