import md5 from 'crypto-js/md5';
declare global {
    interface Window {
        dataLayer?: any;
    }
}

/**
 * Check if the email address is valid.
 * Return a boolean
 */
export function isValidEmail(email: string) {
    // ref. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email
    const regExpEmailCheck = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    return regExpEmailCheck.test(email)
}

export function trackEvent(eventName: string, properties: { email: string }) : void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: eventName,
        email_md5: md5(properties.email).toString(),
    })
}


