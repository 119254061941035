import { Component } from 'react'
import { Form } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import AreaFinder from '../services/AreaFinder'
import IconForRequiredInput from './IconForRequiredInput'
import TooltipForInfo from './TooltipForInfo'

type Props = {
  label: string
  text: string
  selected: string[]
  placeholder: string
  required: boolean
  onChange: (selectedValue: string[]) => void
}

type State = {
  areaFinder: AreaFinder
  availableCities: string[]
  isLoading: boolean
}

export default class FormCitySelect extends Component<Props, State> {

  constructor(props) {
    super(props)

    this.state = {
      areaFinder: new AreaFinder(),
      availableCities: [],
      isLoading: false,
    }

    this.handleSearch = this.handleSearch.bind(this)
  }

  render() {
    return (
      <Form.Group>
        <Form.Label>
          {' '}
          {this.props.label} <IconForRequiredInput show={this.props.required} />
        </Form.Label>
        <TooltipForInfo text={this.props.text} />
        <AsyncTypeahead
          id="form-city-select"
          selected={this.props.selected}
          options={this.state.availableCities}
          isLoading={this.state.isLoading}
          minLength={3}
          multiple 
          labelKey="name"
          placeholder={this.props.placeholder}
          renderMenuItemChildren={option => <span>{option}</span>}
          onSearch={this.handleSearch}
          onChange={this.props.onChange}
          emptyLabel='Nessuna città trovata'

        />
      </Form.Group>
    )
  }

  /**
   * Handle the event triggered when the user starts searching a city.
   */
  handleSearch(query: string) {
    this.setState({ isLoading: true })

    this.state.areaFinder.searchCities(query).then(cities => {
      this.setState({
        availableCities: cities,
        isLoading: false,
      })
    })
  }


}
