import Axios from 'axios'
import iSearchZonesResponseDto from '../../common/iSearchZonesResponseDto'
import { API_URL_SEARCH_ZONES } from '../config/routes'

export default class ZoneFinder {
  /**
   * Find a list of zones matching with the provided selected cities.
   */
  searchZones(selectedCities: string[]): Promise<string[]> {
    return Axios.post<iSearchZonesResponseDto>(API_URL_SEARCH_ZONES, { selectedCities })
      .then(response => response.data.foundZones)
      .catch(() => [])
  }
}
