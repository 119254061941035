export const FIELD_LABELS = {
    cities: "Città",
    zones: "Zone (opzionale)",
    minPriceOption: "Budget Minimo",
    maxPriceOption: "Budget Massimo",
    minMqOption: "Mq Min",
    maxMqOption: "Mq Max",
    minRoomsOption: "Numero locali (opzionale)",
    requestReasonOption: "Motivo della richiesta",
    leadName: "Nome",
    leadSurname: "Cognome",
    leadEmail: "Email",
    leadPhone: "Telefono",
    types: "Tipologia",
    leadNotes: "Note (opzionale)",
  }
