import Link from 'next/link'
import Axios from 'axios'
import { Component } from 'react'
import { Alert, Button, Col, Form } from 'react-bootstrap'
import iLeadFormDto from '../../common/iLeadFormFieldsDto'
import iLeadRequestDto from '../../common/iLeadRequestDto'
import { API_URL_GET_FORM_DATA, API_URL_SUBMIT_FORM } from '../config/routes'
import FormCitySelect from './FormCitySelect'
import FormZoneSelect from './FormZoneSelect'
import FormInput from './FormInput'
import FormSelect from './FormSelect'
import FormTextArea from './FormTextArea'
import FormTypeSelect from './FormTypeSelect'
import IconForRequiredInput from './IconForRequiredInput'
import { FIELD_LABELS } from '../../frontend/config/formLabels'
import { isValidEmail, trackEvent } from '../../helpers/index'
import AreaFinder from '../services/AreaFinder'
import ZoneFinder from '../services/ZoneFinder'

type Props = {
  onSuccess: () => void
}

type State = {
  availablePriceOptions: iLeadFormDto['options']['budget']
  availableMqOptions: iLeadFormDto['options']['mq']
  availableRequestReasonOptions: iLeadFormDto['options']['requestReason']
  availableRoomsOptions: iLeadFormDto['options']['rooms']
  availableTypesOptions: iLeadFormDto['options']['types']
  selectedCities: iLeadRequestDto['cities']
  selectedZones: iLeadRequestDto['zones']
  selectedMinPriceOption: iLeadRequestDto['minPriceOption']
  selectedMaxPriceOption: iLeadRequestDto['maxPriceOption']
  selectedMinMq: iLeadRequestDto['minMqOption']
  selectedMaxMq: iLeadRequestDto['maxMqOption']
  selectedMinRooms: iLeadRequestDto['minRoomsOption']
  selectedRequestReason: iLeadRequestDto['requestReasonOption']
  selectedTypes: iLeadRequestDto['types']
  enteredLeadName: iLeadRequestDto['leadName']
  enteredLeadSurname: iLeadRequestDto['leadSurname']
  enteredLeadEmail: iLeadRequestDto['leadEmail']
  enteredLeadPhone: iLeadRequestDto['leadPhone']
  enteredLeadNotes: iLeadRequestDto['leadNotes']
  privacyConsentCheckbox: boolean
  isFormSubmissionOnGoing: boolean
  showFormError: boolean
  errorFieldName: string
  origin: string

  zoneFinder: ZoneFinder
  optionsZones: iLeadRequestDto['zones']
}

export default class LeadRequestForm extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      availablePriceOptions: [],
      availableMqOptions: [],
      availableRoomsOptions: [],
      availableRequestReasonOptions: [],
      availableTypesOptions: [],
      selectedCities: [],
      selectedZones: [],
      selectedMinPriceOption: '',
      selectedMaxPriceOption: '',
      selectedMinMq: '',
      selectedMaxMq: '',
      selectedRequestReason: '',
      selectedMinRooms: '',
      selectedTypes: [],
      enteredLeadName: '',
      enteredLeadSurname: '',
      enteredLeadEmail: '',
      enteredLeadPhone: '',
      enteredLeadNotes: '',
      privacyConsentCheckbox: false,
      isFormSubmissionOnGoing: false,
      showFormError: false,
      errorFieldName: '',
      origin: null,

      zoneFinder: new ZoneFinder(),
      optionsZones: []
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleOnCityChange = (selectedCities: string[], selectedZonesFromUrl: string[]) => {

    // update current selected cities
    selectedCities = selectedCities.length > 0 ? selectedCities : [] 
    this.setState({ selectedCities })

    // remove selected zones if any selected cities has been removed
    const currentSelectedZones = this.state.selectedZones
    const cleanedZones = currentSelectedZones.filter(zone => 
        selectedCities.some(city => zone.includes(' - ' + city)));
    this.setState({ selectedZones: cleanedZones })

    // update list of options zones according to selected cities
    // update list of selected zones when zones come from the url parameter
    this.state.zoneFinder.searchZones(selectedCities).then(zones => {
        
        if (selectedZonesFromUrl.length > 0) {
            
            // console.log('zones:', zones)
            // console.log('selectedZonesFromUrl:', selectedZonesFromUrl)
            let nomalizedZonesFromUrl =[]
            // check zones coming from url are in the list of available zones
            let matchingZones = zones.filter(zone => {
                let normalizedZone = zone.trim().toLowerCase()
                nomalizedZonesFromUrl = selectedZonesFromUrl.map( zoneFromUrl => zoneFromUrl.trim().toLowerCase())
                return nomalizedZonesFromUrl.includes(normalizedZone)
            })

            // console.log('matchingZones:', matchingZones)

            this.setState({
                selectedZones: matchingZones,
            })
        }

        this.setState({
            optionsZones: zones,
        })
    }) 
  }


  render() {
    return (
      <div>
        <hr />
        <p className="lead mb-4">Abbiamo tante case all'asta da proporti. Cosa ti piacerebbe?</p>
        <Form className="text-left" onSubmit={this.handleSubmit}>
          <Form.Row>
            <Col xs={12} md={6}>
              <FormCitySelect
                label={FIELD_LABELS['cities']}
                selected={this.state.selectedCities}
                text="In quali città la vorresti trovare?"
                placeholder="Scrivi qui le tue città"
                required={true}
                onChange={selectedCities => this.handleOnCityChange(selectedCities, [])}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormZoneSelect
                label={FIELD_LABELS['zones']}
                selected={this.state.selectedZones}
                text="In quali zone cerchi?"
                placeholder="Scrivi qui le tue zone"
                required={false} 
                onChange={selectedZones => this.setState({ selectedZones })}
                selectedCities={ this.state.selectedCities }
                optionsZones={this.state.optionsZones}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} md={6}>
              <FormTypeSelect
                label="Tipologia"
                selected={this.state.selectedTypes}
                text="Quale tipologia stai cercando?"
                availableTypes={this.state.availableTypesOptions}
                placeholder="Seleziona una o più tipologie"
                required={true}
                onChange={selectedTypes => this.setState({ selectedTypes })}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormSelect
                label={FIELD_LABELS['minRoomsOption']}
                text="Di quanti locali minimo hai bisogno?"
                options={this.state.availableRoomsOptions}
                value={this.state.selectedMinRooms}
                required={false}
                onChange={selectedMinRooms => this.setState({ selectedMinRooms })}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} md={6}>
              <FormSelect
                label={FIELD_LABELS['minMqOption']}
                text="Di quanti mq minimo hai bisogno?"
                options={this.state.availableMqOptions}
                value={this.state.selectedMinMq}
                required={true}
                onChange={selectedMinMq => this.setState({ selectedMinMq })}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormSelect
                label={FIELD_LABELS['maxMqOption']}
                text="Di quanti mq massimo hai bisogno?"
                options={this.state.availableMqOptions}
                value={this.state.selectedMaxMq}
                required={true}
                onChange={selectedMaxMq => this.setState({ selectedMaxMq })}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} md={6}>
              <FormSelect
                label={FIELD_LABELS['minPriceOption']}
                text="Qual è il tuo budget minimo?"
                options={this.state.availablePriceOptions}
                value={this.state.selectedMinPriceOption}
                required={true}
                onChange={selectedMinPriceOption => this.setState({ selectedMinPriceOption })}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormSelect
                label={FIELD_LABELS['maxPriceOption']}
                text="Qual è il tuo budget massimo?"
                options={this.state.availablePriceOptions}
                value={this.state.selectedMaxPriceOption}
                required={true}
                onChange={selectedMaxPriceOption => this.setState({ selectedMaxPriceOption })}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} md={6}>
              <FormSelect
                label={FIELD_LABELS['requestReasonOption']}
                text="Qual è il motivo della tua richiesta?"
                options={this.state.availableRequestReasonOptions}
                value={this.state.selectedRequestReason}
                required={true}
                onChange={selectedRequestReason => this.setState({ selectedRequestReason })}
              />
            </Col>
          </Form.Row>
          <hr className="mt-4" />
          <p className="lead text-center mt-2 mb-4">Come possiamo contattarti?</p>
          <Form.Row>
            <Col xs={12} md={6}>
              <FormInput
                label={FIELD_LABELS['leadName']}
                placeholder="Marco"
                value={this.state.enteredLeadName}
                required={true}
                onChange={enteredLeadName => this.setState({ enteredLeadName })}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                label={FIELD_LABELS['leadSurname']}
                placeholder="Rossi"
                value={this.state.enteredLeadSurname}
                required={true}
                onChange={enteredLeadSurname => this.setState({ enteredLeadSurname })}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} md={6}>
              <FormInput
                label={FIELD_LABELS['leadEmail']}
                type="email"
                placeholder="marcorossi@gmail.com"
                value={this.state.enteredLeadEmail}
                required={true}
                onChange={enteredLeadEmail => this.setState({ enteredLeadEmail })}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput
                label={FIELD_LABELS['leadPhone']}
                type="tel"
                placeholder="123456789"
                value={this.state.enteredLeadPhone}
                required={true}
                onChange={enteredLeadPhone => this.setState({ enteredLeadPhone })}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12}>
              <FormTextArea
                label={FIELD_LABELS['leadNotes']}
                placeholder=""
                value={this.state.enteredLeadNotes}
                maxLength={200}
                text="Se vuoi qui puoi aggiungere qualche info aggiuntiva riguardo quello che stai cercando. Max 200 caratteri."
                onChange={enteredLeadNotes => this.setState({ enteredLeadNotes })}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={12}>
              <Form.Check
                className="mr-1"
                inline
                label="Accetto la"
                type={'checkbox'}
                id={`privacyPolicy`}
                checked={this.state.privacyConsentCheckbox}
                name={'privacyConsent'}
                onChange={() => {
                  this.setState({ privacyConsentCheckbox: !this.state.privacyConsentCheckbox })
                }}
              />
              <Link href="https://www.iubenda.com/privacy-policy/80066472">
                <a className="ml-0" target="_blank">
                  Privacy Policy{' '}
                </a>
              </Link>
              <IconForRequiredInput show={true} />
              <p className="text-left mt-4">
                <small className="text-muted">
                  (<IconForRequiredInput show={true} />) campo obbligatorio
                </small>
              </p>
              <Alert
                dismissible
                variant="danger"
                className="my-4 text-center"
                show={this.state.showFormError}
                onClose={() => this.setState({ showFormError: false })}
              >
                <Alert.Heading>C'è stato un errore durante l'invio del form!</Alert.Heading>
                <p>
                  Errore sul campo <strong>{this.state.errorFieldName}</strong>
                </p>
                <p>Per favore, controlla e riprova.</p>
              </Alert>
            </Col>

            <Button
              className="text-white mx-auto"
              variant="primary"
              size="lg"
              type="submit"
              disabled={this.state.isFormSubmissionOnGoing}
            >
              {this.state.isFormSubmissionOnGoing ? 'Sto inviando i dati...' : 'Richiedi'}
            </Button>
          </Form.Row>
        </Form>
      </div>
    )
  }

  componentDidMount() {
    let urlString = new URLSearchParams(window.location.search)
    let origin = urlString.has('origin') ? urlString.get('origin') : 'promo.immobiliallasta.it'

    this.setState({
      origin: origin,
    })

    // prefill form using url parameters, when available
    Axios.get<iLeadFormDto>(API_URL_GET_FORM_DATA).then(response => {
      this.setState({
        availablePriceOptions: response.data.options.budget,
        availableMqOptions: response.data.options.mq,
        availableRoomsOptions: response.data.options.rooms,
        availableRequestReasonOptions: response.data.options.requestReason,
        availableTypesOptions: response.data.options.types,
      })

      if(urlString.has('selectedMinMq') && this.state.availableMqOptions.indexOf(urlString.get('selectedMinMq')) != -1){
        this.setState({
          selectedMinMq: urlString.get('selectedMinMq')
        })
      }

      if(urlString.has('selectedMaxMq') && this.state.availableMqOptions.indexOf(urlString.get('selectedMaxMq')) != -1){
        this.setState({
          selectedMaxMq: urlString.get('selectedMaxMq')
        })
      }

      if(urlString.has('selectedMinPriceOption') && this.state.availablePriceOptions.indexOf(urlString.get('selectedMinPriceOption')) != -1){
        this.setState({
          selectedMinPriceOption: urlString.get('selectedMinPriceOption')
        })
      }

      if(urlString.has('selectedMaxPriceOption') && this.state.availablePriceOptions.indexOf(urlString.get('selectedMaxPriceOption')) != -1){
        this.setState({
          selectedMaxPriceOption: urlString.get('selectedMaxPriceOption')
        })
      }

      if(urlString.has('selectedMinRooms') && this.state.availableRoomsOptions.indexOf(urlString.get('selectedMinRooms')) != -1){
        this.setState({
          selectedMinRooms: urlString.get('selectedMinRooms')
        })
      }

      let selectedTypes = []
      if(urlString.has('selectedTypes')){
        selectedTypes = urlString.get('selectedTypes').split("|").filter(t => this.state.availableTypesOptions.indexOf(t) != -1)
        this.setState({
          selectedTypes: selectedTypes
        })
      }

      let selectedCities = []
      let selectedZonesFromUrl = []
      if (urlString.has('selectedCities')) {
        let areaFinder = new AreaFinder()
        selectedCities = urlString.get('selectedCities').split('|')
        
        selectedCities.map(city => {
            areaFinder.searchCities(city).then(availableCities => {
                this.setState({
                    // use SET to remove duplicates
                    selectedCities: [...new Set([...this.state.selectedCities, ...availableCities])],
                })
                
                if (urlString.has('selectedZones')) {
                    selectedZonesFromUrl = urlString.get('selectedZones').split('|')
                    
                // update selected cities, selected zones (also if coming by URL), options zones
                this.handleOnCityChange(availableCities, selectedZonesFromUrl) 

            }
          })
        })
      }
    })
  }

  /**
   * Handle the event triggered when the user clicks on submit button.
   */
  handleSubmit(event) {
    event.preventDefault()
    let mandatoryStringFields = {
      minMqOption: this.state.selectedMinMq,
      maxMqOption: this.state.selectedMaxMq,
      requestReasonOption: this.state.selectedRequestReason,
      minPriceOption: this.state.selectedMinPriceOption,
      maxPriceOption: this.state.selectedMaxPriceOption,
      leadName: this.state.enteredLeadName,
      leadSurname: this.state.enteredLeadSurname,
      leadEmail: this.state.enteredLeadEmail,
      leadPhone: this.state.enteredLeadPhone,
    }

    // Check that all mandatory string fields has been filled.
    for (let field in mandatoryStringFields) {
      if (typeof mandatoryStringFields[field] !== 'string' || mandatoryStringFields[field].trim() === '') {
        this.setState({ showFormError: true })
        console.log('Errore su campo:', field)
        this.setState({ errorFieldName: FIELD_LABELS[field] })
        return
      }
    }

    // Check madatory field types (array)
    if (!Array.isArray(this.state.selectedTypes) || this.state.selectedTypes.length === 0) {
      this.setState({ showFormError: true })
      console.log('Errore su campo: types')
      this.setState({ errorFieldName: FIELD_LABELS['types'] })
      return
    }
    if (!Array.isArray(this.state.selectedCities) || this.state.selectedCities.length === 0) {
      this.setState({ showFormError: true })
      console.log('Errore su campo: cities')
      this.setState({ errorFieldName: FIELD_LABELS['cities'] })
      return
    }

    // Check Privacy Consent
    if (!this.state.privacyConsentCheckbox) {
      this.setState({ showFormError: true })
      console.log('Errore su privacy checkbox')
      this.setState({ errorFieldName: 'Accetto la Privacy Policy' })
      return
    }

    if (!isValidEmail(this.state.enteredLeadEmail)) {
      console.log(this.state.enteredLeadEmail)
      this.setState({ showFormError: true })
      console.log('Errore sul campo Email')
      this.setState({ errorFieldName: FIELD_LABELS['leadEmail'] })
      return
    }

    // Include other fields to data to be processed server-side.
    let otherFields = {
      cities: this.state.selectedCities,
      zones: this.state.selectedZones,
      types: this.state.selectedTypes,
      minRoomsOption: this.state.selectedMinRooms,
      leadNotes: this.state.enteredLeadNotes,
      origin: this.state.origin,
    }
    let formFields = { ...mandatoryStringFields, ...otherFields }

    this.setState({ isFormSubmissionOnGoing: true })
    Axios.post<iLeadRequestDto>(API_URL_SUBMIT_FORM, formFields)
      .then(() => {
        this.setState({ showFormError: false })
        trackEvent('compilato_form_richiedi', { email: formFields.leadEmail })
        this.props.onSuccess()
      })
      .catch(() => {
        this.setState({ showFormError: true })
      })
      .finally(() => this.setState({ isFormSubmissionOnGoing: false }))
  }
}
