import { FunctionComponent } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsInfo } from 'react-icons/bs'

type Props = {
  text: string
}

const TooltipForInfo: FunctionComponent<Props> = props => (
  <OverlayTrigger placement="right" overlay={<Tooltip id={'tooltip-info-' + props.text}>{props.text}</Tooltip>}>
    <span className="float-right">
      <BsInfo />
    </span>
  </OverlayTrigger>
)

export default TooltipForInfo
