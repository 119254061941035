import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { Jumbotron } from 'react-bootstrap'
import Layout from '../frontend/components/Layout'
import LeadRequestForm from '../frontend/components/LeadRequestForm'
import { PAGE_URL_THANK_YOU } from '../frontend/config/routes'

const Homepage: FunctionComponent = () => {
  const router = useRouter()

  return (
    <Layout title="Contattaci | immobiliallasta.it">
      <Jumbotron className="mt-3 px-3 px-lg-5 shadow">
        <div className="text-center">
          <h1>Cerchi casa?</h1>
          <LeadRequestForm onSuccess={() => router.push(PAGE_URL_THANK_YOU)} />
        </div>
      </Jumbotron>
    </Layout>
  )
}

export default Homepage
