import { FunctionComponent } from 'react'

type Props = {
  show: boolean
}

const IconForRequiredInput: FunctionComponent<Props> = props => (
  <>{props.show ? <span className="text-danger">*</span> : null}</>
)

export default IconForRequiredInput
