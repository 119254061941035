import { Component } from 'react'
import { Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import ZoneFinder from '../services/ZoneFinder'
import IconForRequiredInput from './IconForRequiredInput'
import TooltipForInfo from './TooltipForInfo'

type Props = {
  label: string
  text: string
  selected: string[]
  placeholder: string
  required: boolean
  selectedCities: string[]
  onChange: (selectedValue: string[]) => void
  optionsZones: string[]

}

type State = {
  zoneFinder: ZoneFinder
}

export default class FormZoneSelect extends Component<Props, State> {

  constructor(props) {
    super(props)

    this.state = {
      zoneFinder: new ZoneFinder(),
    }

    this.handleChange = this.handleChange.bind(this)
  }

  render() {
    return (
      <Form.Group>
        <Form.Label>
          {' '}
          {this.props.label} <IconForRequiredInput show={this.props.required} />
        </Form.Label>
        <TooltipForInfo text={this.props.text} />
        <Typeahead
          id="form-zone-select"
          selected={this.props.selected}
          options={this.props.optionsZones}
          multiple 
          labelKey="name"
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          disabled={this.props.selectedCities.length == 0}
          emptyLabel='Nessuna zona trovata'
          //   minLength={3}  // wait minLength chars inserted before to show options
          //   renderMenuItemChildren={option => <span>{option}</span>}
        />
      </Form.Group>
    )
  }

 

  /**
   * Handle the event triggered when the user selects one or more zone.
   */
  handleChange(selectedZones: string[]) {
    let value = selectedZones.length > 0 ? selectedZones : []

    this.props.onChange(value)
  }
}
