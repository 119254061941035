import { FunctionComponent } from 'react'
import { Form } from 'react-bootstrap'
import TooltipForInfo from './TooltipForInfo'

type Props = {
  label: string
  placeholder: string
  value: string
  maxLength: number
  text: string
  onChange: (value: string) => void
}

const FormTextArea: FunctionComponent<Props> = props => (
  <Form.Group>
    <Form.Label>{props.label}</Form.Label>
    <TooltipForInfo text={props.text} />
    <Form.Control
      as="textarea"
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      value={props.value}
      onChange={event => props.onChange(event.target.value)}
    />
  </Form.Group>
)

export default FormTextArea
