import Head from 'next/head'
import { FunctionComponent } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

type Props = {
  title: string
}

const Layout: FunctionComponent<Props> = props => (
  <>
    <Head>
      <title>{props.title}</title>
      <link rel="icon" href="/favicons/favicon.ico" />
    </Head>
    <div>
      <Container>
        <Row>
          <Col className="pt-4 pb-3">
            <Image src="/logo.png" height="64px" className="d-block mx-auto" />
          </Col>
        </Row>
        {props.children}
      </Container>
    </div>
  </>
)

export default Layout
